<template>
<div id="personal">
  <div class="personal-wrap">
    <div class="personal-left">
      <h3><i class="el-icon-arrow-left"></i>返回个人中心</h3>
      <ul>
        <li @click="togSub(1)" :class="{'curP':subP==1}"><span>沟通过</span><label>(10)</label><i class="el-icon-arrow-right"></i></li>
        <li @click="togSub(2)" :class="{'curP':subP==2}"><span>已投递</span><label>(10)</label><i class="el-icon-arrow-right"></i></li>
        <li @click="togSub(3)" :class="{'curP':subP==3}"><span>面试邀请</span><label>(6)</label><i class="el-icon-arrow-right"></i></li>
        <li @click="togSub(4)" :class="{'curP':subP==4}"><span>收藏的职位</span><label>(10)</label><i class="el-icon-arrow-right"></i></li>
        <li @click="togSub(5)" :class="{'curP':subP==5}"><span>关注的公司</span><label>(8)</label><i class="el-icon-arrow-right"></i></li>
      </ul>
    </div>
    <div class="personal-right">
      <h4>沟通过的职位（10）</h4>
      <div class="interview">
        <ul class="interview-header">
          <li @click="togInter(1)" :class="{'curInter':subI==1}"><span>全部</span></li>
          <li @click="togInter(2)" :class="{'curInter':subI==2}"><span>待面试</span></li>
          <li @click="togInter(3)" :class="{'curInter':subI==3}"><span>已完成</span></li>
          <li @click="togInter(4)" :class="{'curInter':subI==4}"><span>未面试</span></li>
          <li @click="togInter(5)" :class="{'curInter':subI==5}"><span>已取消</span></li>
        </ul>
      </div>
      <div class="interview-detail">
        <div class="int-title">
          <span>待面试</span><label>未签到</label>
          <div class="sign-btn">签到</div>
          <div class="cancel-btn">取消面试</div>
        </div>
        <div class="int-company">
           <div class="icompany-info">
            <img src="../assets/imgs/icon.jpg">
            <p>
              <span>XX科技有限公司</span>
              <label>已认证</label>
            </p>
          </div>
          <div class="int-human">
            <img src="../assets/imgs/icon.jpg">
            <span>陈北方 | 人事经理</span>
            <label>继续沟通</label>
          </div>
        </div>
        <p class="inter-line"><span>时间：</span><label>2020-10-21 15：45 </label></p>
        <p class="inter-line"><span>职位：</span><label>产品经理 6-11k </label></p>
        <p class="inter-line"><span>地址：</span><label>新疆省哈密市西河街道12号 </label></p>
        <div class="steps">
          <el-steps direction="vertical" :active="1">
            <el-step title="面试时间到" description="请准时到达面试地点，面试时间到时不能取消"></el-step>
            <el-step title="面试签到" description="提前半小时可签到，告知面试官您已到场"></el-step>
            <el-step title="进行面试" description="面试过程中，如有任何问题，您可以投诉"></el-step>
            <el-step title="面试完成"></el-step>
          </el-steps>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "personal",
  data() {
    return {
      subP:1,
      subI:1,
      activeName:'first'
    }
  },
  methods: {
    togSub(index){
      this.subP = index
    },
    togInter(index){
      this.subI = index
    },
  }
};
</script>

<style lang="scss" scoped>
.personal-wrap {
  width: 1184px;
  min-height: 500px;
  overflow: hidden;
  margin: 0 auto;
  padding: 20px;
}
.personal-left{
  background: #ccc;
  height: 270px;
  width: 200px;
  float: left;
  margin-right: 10px;
  h3{
     height: 45px;
    line-height: 45px;
    padding-left: 10px;
    border-bottom: 1px solid #fff;
  }
  li{
    height: 45px;
    line-height: 45px;
    padding-left: 10px;
    border-bottom: 1px solid #fff;
    cursor: pointer;
     i{
      float: right;
      margin-top: 15px;
      margin-right: 20px;
    }
  }
 
}
.personal-right{
  float: left;
  height: 670px;
  width: 900px;
  h4{
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid #fff;
    padding-left: 14px;
  }
}
.curP{
  color: red;
  background: rgb(69, 206, 151);
}
.curInter{
  border-bottom: 1px solid rgb(69, 206, 151);;
}
.interview{
  .interview-header{
    li{
      float: left;
      width: 70px;
      padding: 4px;
      text-align: center;
      height: 40px;
      line-height: 40px;
      cursor: pointer;
    }
  }
}
.interview-detail{
  clear: both;
  padding: 20px;
  .int-title{
    span{
      font-size: 30px;
    }
    label{
      display: inline-block;
      background: rgb(239, 51, 51);
      width: 50px;
      height: 20px;
      margin-left: 15px;
      line-height: 20px;
      text-align: center;
      color: #fff;
    }
    .sign-btn{
       width: 60px;
      height:25px;
      line-height: 25px;
      text-align: center;
      border: 1px solid rgb(195, 33, 33);
      float: right;
      font-size: 12px;
      margin-left: 10px;
    }
    .cancel-btn{
      width: 60px;
      height:25px;
      line-height: 25px;
      text-align: center;
      border: 1px solid rgb(195, 33, 33);
      float: right;
      font-size: 12px;
    }
  }
  .int-company{
    height: 140px;
    background: #ccc;
    margin-top: 15px;
    padding: 10px;
    .icompany-info{
      height: 70px;
      border-bottom: 1px dotted rgb(122, 52, 52);
       img {
          width: 50px;
          height: 50px;
          display: block;
          float: left;
        }

        p {
          float: left;

          label {
            display: block;
          }
        }
    }
  }
  .int-human{
    padding: 10px;
    img{
      width: 50px;
      height: 50px;
      border-radius: 50%;
      float: left;
      
    }
    span{
      display:block;
      float: left;
      margin:15px 15px;
    }
    label{
      display: block;
      width: 50px;
      height: 25px;
      line-height: 25px;
      background: rgb(22, 142, 185);
      font-size: 12px;
      float: right;
      margin-top: 10px;
    }
  }
  .inter-line{
    margin-top: 15px;
  }
  .steps{
    height: 200px;
    margin-top: 15px;
  }
}
</style><style lang="scss">
.personal-wrap {}
</style>
